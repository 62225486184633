(async () => {
  await import("./main")

  async function loadFancybox() {
    if (document.querySelectorAll("[data-fancybox]").length) {
      await import("./fancybox/fancybox")
    }
  }
  loadFancybox()

})()

async function loadLazyload() {
  if (document.querySelectorAll("[data-src], [data-bg]").length) {
    const { lazyLoading } = await import("./lazyload/lazyload-init")
    window.lazyloading = lazyLoading // add lazyloading to global window object
  }
}
loadLazyload()

async function loadFormValidation() {
  if (document.querySelectorAll('[data-validate="jquery"]').length) {
    await import("./form-validation");
  }
}
loadFormValidation()

async function loadSwiper() {
  if (document.querySelectorAll(".swiper").length) {
    await import("./swiper/swiper-init");
  }
}
loadSwiper();

async function loadTomSelect() {
  if (document.querySelectorAll(".tom-select").length) {
    await import("./tom-select/tom-select");
  }
}
loadTomSelect();
